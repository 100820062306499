import React, {useState, useEffect} from 'react';

const GetInvolved = () => {
    return <main className="network_bg">

    <section className="py-5 text-center container " style={{color: 'white'}}>
      <div className="row py-lg-5">
        <div className="col-lg-6 col-md-8 mx-auto">
          <h1 className="fw-light" style={{color: 'white'}}>Snowlight Learning Foundation</h1>
          <p className="" style={{color: 'white'}}>Fighting educational interruption with technology</p>
        </div>
      </div>
    </section>
  
    <div className="album py-5 bg-light">
      <div className="container">
        <div className="row" style={{minHeight: '100%'}}>
          <div className="col-md-12">
            <h5>How to Get Involved</h5>
            <p>
                We're delighted to hear that you want to get involved. Please look at our active roles below. 
                Even if you don't see anything that fits your profile, 
                feel free to <a href="mailto:admin@snowlight.org" target="_blank">get in touch</a>!
            </p>
            <ul className="list-group vs-lg">
                <li className="list-group-item">
                    <h6>Volunteer</h6>
                    <p>
                        As a startup nonprofit, we need all the help we can get. We cannot fulfill our missions without
                        our passionate and committed supporters, and if you have an idea for how you can contribute, 
                        please don't hesistate to reach out!
                    </p>
                    <p><a target="_blank" href="https://docs.google.com/document/d/19x6lYbT1xh8xXQU8ebD7z27MGCcRjitFr5eVEt6FyUo/edit?usp=sharing">Job Description</a></p>
                </li>
                <li className="list-group-item">
                    <h6>Education Consultant, Dari and Pashto</h6>
                    <p>
                        We are looking for a Dari and Pashto educator to help us understand the curricular needs of students, and 
                        eventually to develop a curriculum that would be delivered to these students.
                    </p>
                    <p><a target="_blank" href="https://docs.google.com/document/d/1-yqpttrrBuk2SKht1SHjKzQv5IAdLd9Rohwu7AtV8qo/edit?usp=sharing">Job Description</a></p>
                </li>
                <li className="list-group-item">
                    <h6>UI/UX Designer</h6>
                    <p>
                        We are looking for a passionate product designer to help us build the best education platform possible.
                    </p>
                    <p><a target="_blank" href="https://docs.google.com/document/d/1iBPGO5aorDnYFLavZCwv7EUSpP8DI75JjMmkwnr0sfI/edit?usp=sharing">Job Description</a></p>
                </li>
                <li className="list-group-item">
                    <h6>Fullstack Developer</h6>
                    <p>
                        We are looking for a passionate fullstack developer to help us build the best education platform possible.
                    </p>
                    <p><a target="_blank" href="https://docs.google.com/document/d/1hnhE1zCLuplqNbpiRd7Pk6BryBgVIz9bibYcJ41SB2U/edit?usp=sharing">Job Description</a></p>
                </li>
                <li className="list-group-item">
                    <h6>Grant Writer</h6>
                    <p>
                        We are looking for a grant writer to help us identify and apply for grants that can be help us further
                        our mission of helping students around the world.  
                    </p>
                    <p><a target="_blank" href="https://docs.google.com/document/d/1jZZv1leobDTfHV3ns_dcePBiMH8JrOy9GLHi4y9WzLU/edit?usp=sharing">Job Description</a></p>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  
  </main>;
}

export default GetInvolved;