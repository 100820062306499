import 'bootstrap/dist/css/bootstrap.min.css';
import './main_page.css';


function App() {
  return (
    <main className="network_bg">

  <section className="py-5 text-center container " style={{color: 'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light" style={{color: 'white'}}>Snowlight Learning Foundation</h1>
        <p className="" style={{color: 'white'}}>Fighting educational interruption with technology</p>
      </div>
    </div>
  </section>

  <div className="album py-5 bg-light">
    <div className="container">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
        
        <div className="col">
          <div className="card main-card shadow-sm">
            <svg className="bd-placeholder-img card-img-top img-world" width="100%" height="225"></svg>
            <div className="card-body">
              <h6>A Global Vision</h6>
              <p className="card-text">All worthy endeavors start with a vision. To understand why we started Snowlight and what problems we're trying to solve, read our vision statement.</p>
              <div className="d-flex justify-content-between align-items-center">
                <div className="btn-group main-btn-group">
                  <a type="button" className="btn btn-sm btn-outline-secondary" href="/vision_statement">Vision Statement</a>
                  <a type="button" className="btn btn-sm btn-outline-secondary" href="/vision_statement_ar">رؤية بيان</a>
                  <a type="button" className="btn btn-sm btn-outline-secondary" href="/vision_statement_dr">بیانیه دید</a>
                  <a type="button" className="btn btn-sm btn-outline-secondary" href="/vision_statement_pst">د لید بیانیه</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card main-card shadow-sm">
            <svg className="bd-placeholder-img card-img-top blue-mosque" width="100%" height="225"></svg>
            <div className="card-body">
              <h6>Focus on Afghanistan</h6>
              <p className="card-text">Afghanistan is suffering from severe educational disruption. We are working with committed backers and local NGOs on the ground to bring education to Afghanistan.</p>
              <div className="d-flex justify-content-between align-items-center">
                <div className="btn-group main-btn-group">
                  <a type="button" className="btn btn-sm btn-outline-secondary" href="/dari">Dari Page</a>
                  <a type="button" className="btn btn-sm btn-outline-secondary" href="/pashto">Pashto Page</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card main-card shadow-sm">
          <svg className="bd-placeholder-img card-img-top community-driven" width="100%" height="225"></svg>
            <div className="card-body">
              <h6>Community-Driven</h6>
              <p className="card-text">As a start-up charity, we are always looking for people to be involved. If you are in support of our mission, get in touch and find out how you can participate.</p>
              <div className="d-flex justify-content-between align-items-center">
                <div className="btn-group main-btn-group">
                  <a type="button" className="btn btn-sm btn-outline-secondary" href="/get_involved">Get Involved</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</main>
  );
}

export default App;
