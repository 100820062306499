import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './data_collection.css';
import axios from 'axios';

const DataCollection = ({language}) => {
  const [dari, setDari] = useState({
    'introducingSnowlight': 'معرفی پروژه نور برفی',
    'signUpToReceiveUpdate': ' برای دریافت معلومات بیشتر در مورد زمان آغاز برنامه های درسی ما، ثبت‌نام کنید!',
    'weWorkWithProfessionals': 'ما به کمک استادان، جامعه اکادمیک ، و کسانیکه بشکل رضاکارانه فعالیت دارند کار می کنیم تا بهترین برنامه درسی ممکن را ایجاد نمایم. متعلمین میتوانند در لپ تاپ، تلفن و یا هر دستگاه هوشمند که به انترنت متصل است، آموزش بیبینند.',
    'weWantToMakeGoodCurric': 'هدف ما ایجاد محتوای باکیفیت به زبان های دری و پشتو و در دسترس قرار دادن رایگان آن به صورت آنلاین است.',
    'freeEdPlatformForAfg': 'ساختار آموزشی رایگان برای افغان ها',
    'freeEduc': 'آموزش رایگان',
    'highQual': 'کیفیت بالا',
    'email': 'ایمیل',
    'register': 'ثبت نام',
    'dari': 'دری',
    'pashto': 'پشتو',
    'projSnowLight': ' پروژه نور برفی',
    'thankyou': 'تشکر'
  })
  const [pashto, setPashto] = useState({
    'introducingSnowlight': 'د پروژې د واورې رڼا معرفي کول',
    'freeEdPlatformForAfg': 'د افغانانو لپاره د وړیا زده کړې پلیټ فارم',
    'weWantToMakeGoodCurric': 'زموږ موخه په دري او پښتو ژبو د لوړ کیفیت منځپانګې جوړول او په وړیا توګه آنلاین وړاندې کول دي.',
    'weWorkWithProfessionals': 'موږ د ښوونکو، اکادمیکانو او مخورو سره کار کوو ترڅو غوره نصاب ته وده ورکړي.  په خپل لپ ټاپ، تلیفون او هر بل وسیله د انټرنیټ اتصال سره زده کړئ.',
    'signUpToReceiveUpdate': 'د تازه معلوماتو ترلاسه کولو لپاره لاسلیک وکړئ کله چې زموږ نصاب ژوندی وي!',
    'freeEduc': 'وړیا زده کړه',
    'highQual': 'لوړ کیفیت',
    'email': 'برېښنالیک',
    'register': 'نلیک اپ',
    'dari': 'دري',
    'pashto': 'پښتو',
    'projSnowLight': 'پروژه سنو لائټ',
    'thankyou': 'مننه'
  });
  const [langUse, setLangUse] = useState({});
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const getEmail = () => {
    axios.post('https://api.mavenai.app/collect_email', {email, language})
    .then(r => r.data)  
    .then((d) => {
        console.log(d);
        setMessage(langUse.thankyou || 'Thank you!');
        setTimeout(() => {
          setMessage('');
          setEmail('');
        }, 5000);
      })
  }
  useEffect(() => {
    if (language === 'dari') {
      setLangUse(dari);
      document.title = dari.projSnowLight;
      
    } else if (language === 'pashto') {
      setLangUse(pashto);
      document.title = pashto.projSnowLight;
    }
  }, [language]);
  
  return (
    <div className="afg-bg" style={{height: '100%', width: '100%'}}>
    <section className="py-5 text-center container">
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">{langUse.introducingSnowlight || 'Introducing Project Snowlight'}</h1>
        <p className="lead text-muted">{langUse.freeEdPlatformForAfg || 'A free education platform for Afghans'}</p>
        <p>
          <a href="/dari" className={language === 'dari'? "btn btn-primary my-2": "btn btn-secondary my-2"}>{langUse.dari || 'Dari'}</a>{' '}
          <a href="/pashto" className={language === 'pashto'? "btn btn-primary my-2": "btn btn-secondary my-2"}>{langUse.pashto || 'Pashto'}</a>
        </p>
        
      </div>
      
    </div>
    <div className="row align-items-md-stretch">
      <div className="col-md-6">
        <div className="h-100 p-5 bg-light border rounded-3">
          <h2>{langUse.freeEduc || "Learn Anywhere"}</h2>
          <p>{langUse.weWantToMakeGoodCurric || 'Our goal is to develop high-quality educational content in Dari and Pashto and make it freely available online.'} </p>
        </div>
      </div>
      <div className="col-md-6">
        <div className="h-100 p-5 bg-light border rounded-3">
          <h2>{langUse.highQual || 'High Quality Content'}</h2>
          <p>{langUse.weWorkWithProfessionals || 'We work with educators, academics, and philanthropists to develop the best curriculum possible.'} </p>
        </div>
      </div>
    </div>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <p className="lead text-muted">{langUse.signUpToReceiveUpdate || "Sign up to receive updates when we are live!"}</p>
        <div className="input-group mb-3">
          <input type="text" className="form-control" value={email} onChange={e => setEmail(e.target.value)} placeholder={langUse.email || "Your Email"} />
          <div className="input-group-append">
            <button className="btn btn-primary" type="button" onClick={getEmail}>{langUse.register || "Sign up"}</button>
          </div>
        </div>
        <p>{message}</p>
        
      </div>
    </div>
  </section>
  </div>
  );
}

export default DataCollection;
