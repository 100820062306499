import React, {useState, useEffect} from 'react';
import './vision_statement.css';

const visionStatementText = {
  "snowlight_learning": {
    'en': 'Snowlight Learning Foundation',
    'ar': 'التعليمية الثلج بريق مؤسسة',
    'dr': 'بنياد معرفت رزق و برق برفی',
    'pst': 'د واورې رڼا زده کړې بنسټ',
  },
  "fight_ed_int": {
    'en': "Fighting educational interruption with technology",
    'ar': 'بالتكنولوجيا التعليمي االنقطاع مكافحة',
    'dr': 'مبارزه با وقفه آموزش با فناوری',
    'pst': 'د ټیکنالوژۍ سره د زده کړې خنډ سره مبارزه',

  },
  "snowlight_vision_statement": {
    "en": "Snowlight Vision Statement",
    "ar": 'بالتكنولوجيا التعليمي االنقطاع مكافحة',
    "dr": 'يبیانیه ديد رزق و برق برف',
    'pst': 'د واورې رڼا لید بیان',
  },
  "pr1": {
    'en': `According to legend, the Han-dynasty scholar Che Yin was born in the midst of a bloody civil war. 
    Despite showing great promise, he was forced to drop out of school at a young age to support his family. 
    As hard as the times were, Che Yin never gave up on his dream of becoming a scholar. He would work 
    during the day, and at night he would continue his studies using the light reflected from the snow. 
    He eventually overcame his circumstances, becoming a respected scholar who played a key role in 
    the administration of the state that emerged from the civil war.`,
    'ar': `وفقًا للأسطورة، ولد عالم أسرة هان تشي يين في خضم حرب أهلية دامية. وعلى الرغم من أنه كان واعدًا جدًا، فقد اضطر إلى ترك
    المدرسة في سن مبكرة لإعالة أسرته. على الرغم من صعوبة الزمن، لم يتخل تشي يين أبدًا عن حلمه في أن يصبح عالمًا. كان يعمل في
    النهار، وفي الليل يتابع دراسته مستخدماً الضوء المنعكس عن الثلج. وفي النهاية تغلب على ظروفه، وأصبح عالمًا محترمًا لعب دورًا
    رئيسيًا في إدارة الدولة التي خرجت من الحرب الأهلية`,
    'dr': `طبق افسانه، چه یین، محقق سلسله هان، در بحبوحه یک جنگ داخلی خونین متولد شد. او علیرغم اینکه وعده های زیادی داشت، در سنین پایین مجبور به ترک تحصیل برای تامین معاش خانواده شد. به همان اندازه که روزگار سخت بود، چه یین هرگز از رویای خود برای محقق شدن دست نکشید. روزها کار می کرد و شب ها با استفاده از نور منعکس شده از برف به تحصیل ادامه می داد. او در نهایت بر شرایط خود غلبه کرد و به یک دانشمند محترم تبدیل شد که نقشی کلیدی در اداره کشور برخاسته از جنگ داخلی داشت`,
    'pst': 'د افسانې له مخې، د هان کورنۍ عالم چی ین د یوې خونړۍ کورنۍ جګړې په منځ کې زیږیدلی و. د لویې ژمنې ښودلو سره سره، هغه مجبور شو چې د خپلې کورنۍ د ملاتړ لپاره په ځوان عمر کې ښوونځي پریږدي. څومره چې وخت سخت وو، چی ین هیڅکله د عالم کیدو خپل خوب نه پریږدي. هغه به د ورځې په اوږدو کې کار کاوه او د شپې به یې د واورو څخه منعکس شوي رڼا په کارولو سره خپلو زده کړو ته دوام ورکړ. هغه په ​​​​پای کې په خپلو شرایطو کې بریالی شو، یو درناوي عالم شو چې د دولت په اداره کې یې مهم رول لوبولی و چې د کورنۍ جګړې څخه راڅرګند شو',
  },
  'pr2': {
    'en': `Nearly 2000 years later, we see similar challenges being repeated all over the world. War, political upheaval,
    and economic turmoil has forced millions of children out of school. The interrupted education leads to
    lost opportunities and foregone futures, and ultimately plays a role in perpetuating the cycles of poverty and 
    disenfranchisement.`,
    'ar': `وبعد مرور ما يقرب من ٢٠٠٠ عام، نرى تحديات مماثلة تتكرر في جميع أنحاء العالم. لقد أجبرت الحروب والاضطرابات السياسية
    والاضطرابات الاقتصادية ملايين الأطفال على ترك المدارس. ويؤدي انقطاع التعليم إلى ضياع الفرص و مستقبل مبدد ، ويلعب في نهاية
    المطاف دورا في إدامة دوامة الفقر والحرمان`,
    'dr': `زدیک به ۲۰۰۰ سال بعد، شاهد تکرار چالش های مشابه در سراسر جهان هستیم. جنگ، تحولات سیاسی و آشفتگی های اقتصادی میلیون ها کودک را مجبور به ترک تحصیل کرده است. وقفه در آموزش منجر به فرصت‌های از دست رفته و آینده‌های از دست رفته می‌شود و در نهایت در تداوم چرخه‌های فقر و بی‌حقوقی نقش دارد.`,
    'pst': 'نږدې ۲۰۰۰ کاله وروسته، موږ وینو چې ورته ننګونې په ټوله نړۍ کې تکرار کیږي. جګړې، سیاسي ناکراریو او اقتصادي ناکرارۍ په میلیونونو ماشومان له ښوونځیو څخه بې برخې کړي دي. مداخله شوې زده کړه د فرصتونو له لاسه ورکولو او تیرې راتلونکې لامل کیږي، او په نهایت کې د فقر او بې واکۍ د دورې په دوام کې رول لوبوي',
  },
  'pr3': {
    'en': `Nowhere is this challenge more pronounced than in Afghanistan. With schools shuttered, education has become an 
    unattainable dream for many women and girls. Due to the ongoing economic crisis, even more students
    are forced out of schools. The education interruption has robbed many Afghans of opportunities for a better future.`,
    'ar': `ولا يوجد مكان يتجلى فيه هذا التحدي أكثر مما هو عليه في أفغانستان. مع إغلاق المدارس، أصبح التعليم حلما بعيد المنال بالنسبة للعديد
    من النساء والفتيات. وبسبب الأزمة الاقتصادية المستمرة، يضطر المزيد من الطلاب إلى ترك المدارس. لقد حرم انقطاع التعليم العديد من
    الأفغان من فرص تحقيق مستقبل أفضل`,
    'dr': `این چالش در هیچ کجا به اندازه افغانستان آشکار نیست. با تعطیلی مدارس، آموزش برای بسیاری از زنان و دختران به آرزویی دست نیافتنی تبدیل شده است. به دلیل ادامه بحران اقتصادی، حتی تعداد بیشتری از دانش آموزان مجبور به ترک مدارس می شوند. وقفه تحصیلی بسیاری از افغان ها را از فرصت های آینده بهتر ربوده است`,
    'pst': `دا ننګونه د افغانستان په پرتله په بل ځای کې نه ده څرګنده. د ښوونځیو په تړلو سره، زده کړه د ډیرو ښځو او نجونو لپاره یو نه پوره کیدونکی خوب ګرځیدلی دی. د روان اقتصادي کړکېچ له امله لا ډېر زده کوونکي له ښوونځيو وتلو ته اړ شوي دي. د ښوونې او روزنې خنډ د ډیرو افغانانو فرصتونه د ښه راتلونکي لپاره غلا کړي دي`,

  },
  'pr4': {
    'en': `Recent advances in informational technology has been nothing short of transformative for every industry. For the 
    first time in history, information can be created and delivered almost anywhere in the world. With growing adoption
    of the internet, information technology is no longer a platform exclusively for the wealthy and the privileged. With Snowlight,
    we hope to use technology to combat education interruptions. By developing high-quality and tailored educational 
    content and making it freely available, we aim to help students continue their education and build a future for themselves
    and their country. `,
    'ar': `لم تكن التطورات الحديثة في تكنولوجيا المعلومات أقل من كونها تحويلية لكل صناعة. لأول مرة في التاريخ، يمكن إنشاء المعلومات
    وتسليمها في أي مكان في العالم تقريبًا. مع تزايد الاعتماد على الإنترنت، لم تعد تكنولوجيا المعلومات منصة حصرية للأغنياء والمتميزين.
    مع بريق الثلج، نأمل في استخدام التكنولوجيا لمكافحة انقطاع التعليم. من خلال تطوير محتوى تعليمي عالي الجودة ومصمم خصيصًا
    وإتاحته مجانًا، نهدف إلى مساعدة الطلاب على مواصلة تعليمهم وبناء مستقبل لأنفسهم ولبلدهم`,
    'dr': `پیشرفت‌های اخیر در فناوری اطلاعات برای هر صنعت چیزی جز تحول‌آفرین نبوده است. برای اولین بار در تاریخ، تقریباً در هر نقطه از جهان می توان اطلاعات ایجاد و تحویل داد. با پذیرش رو به رشد اینترنت، فناوری اطلاعات دیگر پلتفرمی منحصراً برای افراد ثروتمند و ممتاز نیست. با رزق و برق برفی، امیدواریم بتوانیم از فناوری برای مبارزه با وقفه های آموزشی استفاده کنیم. هدف ما با توسعه محتوای آموزشی با کیفیت و مناسب و در دسترس قرار دادن آن به صورت رایگان، کمک به دانش آموزان برای ادامه تحصیل و ساختن آینده ای برای خود و کشورشان است`,
    'pst': `په معلوماتي ټیکنالوژۍ کې وروستي پرمختګونه د هر صنعت لپاره د بدلون وړ ندي. په تاریخ کې د لومړي ځل لپاره، معلومات د نړۍ په هر ځای کې جوړ او لیږدول کیدی شي. د انټرنیټ د مخ په زیاتیدونکي اختیار سره، معلوماتي ټیکنالوژي نور د شتمنو او امتیازاتو لپاره یو پلیټ فارم نه دی. د سنو لائټ سره، موږ هیله لرو چې د زده کړې خنډونو سره د مبارزې لپاره ټیکنالوژي وکاروو. د لوړ کیفیت او مناسب تعلیمي مینځپانګې رامینځته کولو او په وړیا ډول د لاسرسي په کولو سره ، موږ هدف لرو چې زده کونکو سره مرسته وکړو چې خپلې زده کړې ته دوام ورکړو او د ځان او خپل هیواد لپاره راتلونکي جوړ کړو`,
  },
  'pr5': {
    'en': `Founded in 2023, Snowlight is still a small organization. But small is powerful. As American Activist Margaret Mead once
    said, "Never underestimate the power of a small group of committed people to change the world. In fact, it is the only thing that ever has."
    Through our small group of committed supporters, we are working daily to become the enabler to students during this difficult time,
    just like the light of snow did for Che Yin during his time 2000 years ago.  `,
    'ar': `تأسست عام ٢٠٢٣, لا تزال بريق الثلج مؤسسة صغيرة. ولكن الصغيرة قوية. وكما قالت الناشطة الأمريكية مارغريت ميد ذات مرة: &quot;لا
    تقلل أبدًا من قوة مجموعة صغيرة من الأشخاص الملتزمين في تغيير العالم. في الواقع، هذا هو الشيء الوحيد الذي لديه على الإطلاق.&quot; من
    خلال مجموعتنا الصغيرة من الداعمين الملتزمين، نعمل يوميًا لنصبح عامل تمكين للطلاب خلال هذا الوقت العصيب، تمامًا كما فعل بريق
    الثلج لتشي ين خلال فترة وجوده قبل ٢٠٠٠ عام`,
    'dr': `رزق و برق برفی که در سال ۲۰۲۳ تأسیس شد، هنوز یک سازمان کوچک است. اما کوچک قدرتمند است. همانطور که مارگارت مید، فعال آمریکایی یک بار گفت: "هرگز قدرت گروه کوچکی از افراد متعهد را برای تغییر جهان دست کم نگیرید. در واقع، این تنها چیزی است که تاکنون داشته است." از طریق گروه کوچکی از حامیان متعهد خود، ما روزانه تلاش می کنیم تا در این دوران سخت، به دانش آموزان کمک کنیم، درست مانند نور برف برای چه یین در زمان او ۲۰۰۰ سال پیش`,
    'pst': `په ۲۰۲۳ کې تاسیس شوی، سنو لائټ لاهم یو کوچنی سازمان دی. مګر کوچنی ځواکمن دی. لکه څنګه چې امریکایی فعاله مارګریټ میډ یو ځل وویل، "هیڅکله د ژمنو خلکو د یوې کوچنۍ ډلې ځواک ته د نړۍ د بدلون لپاره کم مه ګورئ. په حقیقت کې، دا یوازینی شی دی چې تل یې درلود." زموږ د ژمنو ملاتړو د یوې کوچنۍ ډلې له لارې، موږ هره ورځ هڅه کوو چې په دې سخت وخت کې زده کونکو ته وړتیا ورکړو، لکه څنګه چې د واورو رڼا ۲۰۰۰ کاله دمخه د هغه په ​​وخت کې د چی ین لپاره کړې وه`,
  },
  'pr6': {
    'en': `If you would like to be part of our project, please see our page for how you can get involved`,
    'ar': `إذا كنت تريد أن تكون جزءًا من مشروعنا، فيرجى الاطلاع على صفحتنا للتعرف على كيفية المشاركة`,
    'dr': `اگر می خواهید بخشی از پروژه ما باشید، لطفاً صفحه ما را ببینید که چگونه می توانید مشارکت کنید`,
    'pst': `که تاسو غواړئ زموږ په پروژه کې برخه واخلئ، مهرباني وکړئ زموږ پاڼه وګورئ چې تاسو څنګه ګډون کولی شئ`,
  }
}
const save = `If you would like to be part of our project, please see our page for how you can <a href="/get_involved">get involved</a>.`
const VisionStatement = ({lang}) => {
  const langUse = lang || 'ar';
  const alignment = {
    'ar': 'right',
    'dr': 'right',
    'pst': 'right',
    'en': 'left' 
  }[lang]
  const direction = {
    'ar': 'rtl',
    'dr': 'rtl',
    'pst': 'rtl',
    'en': 'ltr' 
  }[lang];
    return <main className="network_bg">

    <section className="py-5 text-center container " style={{color: 'white'}}>
      <div className="row py-lg-5">
        <div className="col-lg-6 col-md-8 mx-auto">
          <h1 className="fw-light" style={{color: 'white'}}>{visionStatementText['snowlight_learning'][langUse]}</h1>
          <p className="" style={{color: 'white'}}>{visionStatementText['fight_ed_int'][langUse]}</p>
        </div>
      </div>
    </section>
  
    <div className="album py-5 bg-light">
      <div className="container">
        <div className="row" style={{minHeight: '100%'}}>
          <div className="col-md-12">
            <ul className="list-group vs-lg">
                <li className="list-group-item">
                    <center><h5>{visionStatementText['snowlight_vision_statement'][langUse]}</h5></center>
                    <p style={{textAlign: alignment, direction}}>
                      {visionStatementText['pr1'][langUse] + (direction === 'rtl'? '.': '')}
                    </p>
                    <p style={{textAlign: alignment, direction}}>
                      {visionStatementText['pr2'][langUse] + (direction === 'rtl'? '.': '')}
                    </p>
                    <p style={{textAlign: alignment, direction}}>
                      {visionStatementText['pr3'][langUse] + (direction === 'rtl'? '.': '')}
                    </p>
                    <p style={{textAlign: alignment, direction}}>
                      {visionStatementText['pr4'][langUse] + (direction === 'rtl'? '.': '')}
                    </p>
                    <p style={{textAlign: alignment, direction}}>
                      {visionStatementText['pr5'][langUse] + (direction === 'rtl'? '.': '')}
                    </p>
                    <p style={{textAlign: alignment, direction}}>
                      {visionStatementText['pr6'][langUse] + (direction === 'rtl'? '.': '')}
                    </p>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  
  </main>;
}

export default VisionStatement;