import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import DataCollection from './components/data_collection/DataCollection'
import MainPage from './components/main_page/MainPage';
import GetInvolved from "./components/get_involved/GetInvolved";
import VisionStatement from "./components/vision_statement/VisionStatement";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/dari",
    element: <DataCollection language={'dari'} />
  },
  {
    path: "/pashto",
    element: <DataCollection language={'pashto'}/>
  },
  {
    path: "/get_involved",
    element: <GetInvolved></GetInvolved>
  },
  {
    path: "/vision_statement",
    element: <VisionStatement lang={"en"} />
  },
  {
    path: "/vision_statement_ar",
    element: <VisionStatement lang={"ar"} />
  },
  {
    path: "/vision_statement_dr",
    element: <VisionStatement lang={"dr"} />
  },
  {
    path: "/vision_statement_pst",
    element: <VisionStatement lang={"pst"} />
  }
]);

// Eliminating Education Inequalities
// Global Vision
// Dari and Pashto programs

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);